import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import tw from 'twin.macro';
import BlockContent from '@sanity/block-content-to-react';

import PageContainer from '../components/page-container';
import SEO from '../components/seo';
import {
  TitleBar,
  PageTitle,
  SectionTitle,
  PageSection,
  FlexContainer,
  FlexItem,
  TextContainer,
  ImageContainer,
} from '../utils/elements.stc';

const DiscountText = tw(TextContainer)`md:w-full md:mb-6 text-gray-800`;

const FlyFishingPage = ({ data }) => {
  const { _rawBody, seo } = data.page;
  const headerImage = data.page.headerImage.asset.fluid;

  return (
    <PageContainer>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="font-light md:font-light">{seo.title}</PageTitle>
      </TitleBar>
      <PageSection>
        <FlexContainer wfull>
          <FlexItem tw="md:w-full md:pr-4">
            <ImageContainer>
              <Img fluid={headerImage} alt="Fly fishing in Basalt Colorado" />
            </ImageContainer>
            <TextContainer wfull>
              <BlockContent blocks={_rawBody} />
            </TextContainer>
          </FlexItem>
        </FlexContainer>
      </PageSection>
    </PageContainer>
  );
};

export default FlyFishingPage;

export const query = graphql`
  query FlyfishingPageQuery {
    page: sanityFlyfishingPage {
      seo {
        title
        keywords
        description
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      headerImage {
        asset {
          fluid(maxWidth: 750, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
